<template>
  <HeaderComponent
    @leistungen="jumpToLeistungen"
    @aboutMe="jumpToAboutMe"
    @contact="jumpToContact"
  />,
  <div class="img-wrapper">
    <div class="textwrapper">
      <h1 class="heading">Tonbildhauer</h1>
      <div class="claimer">Dein Zuhause - dein Kunstwerk</div>
    </div>
    <img class="phoenix" alt="Phoenix" src="../assets/imgs/phoenix2.jpg" />
    <img class="arrow" :src="arrow" alt="pfeil" @click="jumpToLeistungen" />
    <div class="overlay"></div>
    <div ref="service" class="markerService"></div>
  </div>

  <div>
    <Service />
    <img-component />
    <PriceAndShipping />
    <div ref="aboutMe">
      <AboutMe />
    </div>

    <div ref="contact">
      <ContactFormular />
    </div>
  </div>
  <FooterComponent />
</template>

<script>
import arrow from "@/assets/svgs/arrow.svg";
import AboutMe from "@/components/AboutMe.vue";
import ImgComponent from "@/components/ImgComponent.vue";
import Service from "@/components/ServiceComp.vue";
import PriceAndShipping from "@/components/PriceAndShipping.vue";
import ContactFormular from "@/components/ContactFormular.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "HomeView",
  components: {
    AboutMe,
    Service,
    ContactFormular,
    HeaderComponent,
    FooterComponent,
    ImgComponent,
    PriceAndShipping,
  },
  data() {
    return {
      arrow,
    };
  },
  methods: {
    jumpToLeistungen() {
      this.$refs.service.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
    jumpToAboutMe() {
      this.$refs.aboutMe.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
    jumpToContact() {
      this.$refs.contact.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.markerService {
  position: absolute;
  top: 1100px;
}
.img-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  top: 50px;
  clip-path: polygon(50% 0%, 100% 0, 100% 75%, 50% 100%, 0% 75%, 0 0);
  margin-bottom: 3em;
  @media screen and (max-width: 700px) {
    clip-path: none;
  }

  .phoenix {
    height: 93.5vh;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    animation: move-left-right 15s ease-in-out infinite;
    transform: scale(0.5);
    @media screen and (max-width: 700px) {
      animation: zoom-in 15s ease-out infinite,
        move-left-right 40s ease-in-out infinite;
    }
  }

  .arrow {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    bottom: 0px;
    z-index: 999999;
    cursor: pointer;
    animation: wave 3s ease-in infinite;
  }

  @keyframes move-left-right {
    0% {
      transform: translateX(0%) scale(1.15);
    }
    50% {
      transform: translateY(6%) scale(1.1);
    }
    100% {
      transform: translateY(-0%) scale(1.15);
    }
  }

  @keyframes wave {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 156, 156, 0.459);
    opacity: 0.3;
    transition: background-color 1s ease, transform 0.5s ease;

    &:hover {
      background-color: rgb(34, 19, 3);
    }
  }

  .textwrapper {
    position: absolute;
    width: 100%;

    .heading {
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translate(-50%, -50%);
      z-index: 3;
      color: white;
      font-size: 7rem;
      pointer-events: none;

      @media (max-width: 1000px) {
        font-size: 3em;
      }
      @media (max-width: 550px) {
        font-size: 2em;
      }
    }
    .claimer {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 70px;
      z-index: 3;
      font-size: 2em;
      font-family: Unique;

      @media (max-width: 970px) {
        font-size: 1em;
        width: 300px;
      }
      @media (max-width: 450px) {
        font-size: 1em;
        width: 300px;
        left: 53%;
      }
    }
  }
}
</style>
