<template>
  <div class="wrapper">
    <h2>Leistung</h2>
    <p>
      Verleihe deinem Zuhause ein einzigartiges Flair mit einer
      maßgeschneiderten Wunschskulptur von Tonbildhauer. Jedes Kunstwerk wird
      individuell auf deine Bedürfnisse abgestimmt und fügt sich nahtlos in
      deine Einrichtung ein. Lass dich inspirieren und erschaffe mit
      Tonbildhauer dein persönliches Kunstwerk für Zuhause. Es eignet sich auch
      perfekt als Geschenk für ganz besondere Anlässe.
    </p>
    <p>
      Jede Skulptur ist ein absolutes Unikat. Der ölbasierende Ton, den ich
      verwende, härtet niemals aus, was bedeutet, dass jede Skulptur so weich
      und detailliert bleibt, wie ich es bei der Modellierung intendiert habe.
      Dies gibt meinen Skulpturen eine einzigartige Haptik und ein besonderes
      visuelles Erscheinungsbild.
    </p>
    <p>
      Lassen Sie uns gemeinsam etwas Einzigartiges und Unvergessliches schaffen!
    </p>
  </div>
</template>

<style lang="less" scoped>
.wrapper {
  margin-top: 10rem;
  margin-bottom: 3rem;
  max-width: 1300px;
  margin-inline: auto;
  border: 4px inset grey;
  border-radius: 20px;
  padding: 1rem;
  position: relative;
  transform: scale(1);
  transition: transform 1s ease, background 1s ease;
}
.wrapper:hover {
  transform: scale(1.02);
  _background: linear-gradient(rgb(0, 0, 0));
  background-color: rgba(255, 156, 156, 0.048);
}
</style>
