<template>
  <div class="wrapper">
    <h2>Preise und Sonstiges</h2>
    <p>
      Bei meinen Tonbildhauer-Arbeiten richten sich die Preise nach den
      individuellen Wünschen des Kunden, der Größe der Skulptur, den Details und
      dem Aufwand. Dabei ist es mir wichtig, dass der Kunde stets im Vorfeld
      weiß, welche Kosten auf ihn zukommen. Deshalb kalkuliere ich die Preise im
      Voraus und bespreche diese transparent mit Ihnen. So haben Sie die
      Möglichkeit, Ihre individuelle Tonbildhauer-Skulptur nach Ihren
      Vorstellungen und Ihrem Budget zu gestalten.
    </p>

    <p />
    <p>
      Versand: Ich empfehle Ihnen eine Abholung der Skulptur. Hierbei ist
      gewährleistet, dass das Kunstwerk sicher bei Ihnen ankommt. Aufgrund der
      Gefahr von Beschädigungen während des Transports durch den Transporteur
      rate ich vom Versand ab. Auch wenn ich jeden Versand sorgfältig und
      professionell vorbereite, kann ich nicht garantieren, dass das Kunstwerk
      unbeschädigt bei Ihnen ankommt.
    </p>
    <p>
      Sonstiges: Die Skulpturen sind ausschließlich für den Indoor Bereich
      bestimmt und sollten dauerhaft keiner direkten Sonneneinstahlung
      ausgesetzt werden.
    </p>
  </div>
</template>

<style lang="less" scoped>
.wrapper {
  margin-top: 5rem;
  margin-bottom: 3rem;
  max-width: 1300px;
  margin-inline: auto;
  border: 4px inset grey;
  border-radius: 20px;
  padding: 1rem;
  position: relative;
  transform: scale(1);
  transition: transform 1s ease, background 1s ease;
}
.wrapper:hover {
  transform: scale(1.02);
  _background: linear-gradient(rgb(0, 0, 0));
  background-color: rgba(255, 156, 156, 0.048);
}
</style>
