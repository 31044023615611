<template>
  <div class="wrapper">
    <h2>Über mich</h2>
    <p>
      Mein Name ist Matthias, ich bin 29 Jahre alt und ich bin ein begeisterter
      Künstler, der sich auf die Herstellung von Skulpturen aus Ton
      spezialisiert hat. Schon seit meiner Kindheit habe ich mich für Kunst und
      Kreativität begeistert, und diese Leidenschaft hat sich im Laufe der Jahre
      zu meiner beruflichen Berufung entwickelt. Ich liebe es, mit meinen Händen
      zu arbeiten und meine Vorstellungskraft zum Leben zu erwecken, indem ich
      Ton in verschiedene Formen und Figuren bringe. Als aufgeschlossener Mensch
      liebe ich es, neue Leute kennenzulernen und meine Erfahrungen und Ideen
      auszutauschen. Ich betrachte jeden Kunden und jedes Projekt als
      einzigartig und maßgeschneidert und arbeite eng mit meinen Kunden
      zusammen, um sicherzustellen, dass das endgültige Ergebnis ihren
      Erwartungen entspricht. Ich freue mich darauf, Ihnen meine Kunst und meine
      Fähigkeiten als Bildhauer zur Verfügung zu stellen und Ihre Ideen zum
      Leben zu erwecken. Wenn Sie Fragen haben oder weitere Informationen
      wünschen, zögern Sie bitte nicht, mich zu kontaktieren.
    </p>
  </div>
</template>

<style scoped lang="less">
.wrapper {
  margin-top: 5rem;
  margin-bottom: 3rem;
  max-width: 1300px;
  margin-inline: auto;
  border: 4px inset grey;
  border-radius: 20px;
  padding: 1rem;
  position: relative;
  transform: scale(1);
  transition: transform 1s ease, background 1s ease;
}
.wrapper:hover {
  transform: scale(1.02);
  _background: linear-gradient(rgb(0, 0, 0));
  background-color: rgba(255, 156, 156, 0.048);
}
</style>
