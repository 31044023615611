<template>
  <router-view />
</template>

<style lang="less">
@color-black-primary: rgb(7, 6, 6);
@header-footer-height-mobile: 9em;
@mobile-font-size: 14px;

@font-face {
  font-family: unique;
  src: url(./assets/fonts/Unique.ttf);
  font-weight: 500;
}

* {
  margin: 0;
  box-sizing: border-box;
  user-select: none;
}

:root {
  max-width: 100wv;
  margin-inline: auto;
  letter-spacing: 2px;
  background-color: @color-black-primary;
  color: white;
}

h1,
h2,
h3,
h4 {
  font-family: unique, sans-serif;
  font-size: 3em;
  letter-spacing: 0.2em;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 1.5em;
  }
}

p {
  font-family: "Georgia", serif;
  font-size: 2.2em;
  font-weight: 100;
  line-height: 1.5;
  text-align: center;
  margin-inline: auto;
  max-width: 1250px;
  margin-block: 2rem;

  @media (max-width: 500px) {
    font-size: @mobile-font-size;
    margin-inline: 1em;
    text-align: left;
  }

  @media (min-width: 500px) {
    font-size: 17px;
    max-width: 1250px;
    text-align: left;
    margin-inline: auto;
    padding-inline: 1em;
  }
}
</style>
