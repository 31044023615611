<template>
  <header class="nav-menu">
    <i class="fas fa-bars" @click="showMenu()"
      ><img :src="hamburger" alt="menu" />
    </i>

    <div
      class="nav-content"
      :class="this.showMobileMenu ? 'open-menu' : 'closed-menu'"
    >
      <!-- <div class="logo">tonbildhauer.de</div> -->

      <ul class="nav-items">
        <li @click="leistungen">Leistungen</li>
        <li @click="aboutMe">Über mich</li>
        <li @click="contact">Kontakt</li>
      </ul>
    </div>
    <div class="arrow"></div>
    <div class="arrow2"></div>
  </header>
</template>

<script>
import hamburger from "@/assets/svgs/hamburger.svg";
export default {
  data() {
    return {
      showMobileMenu: false,
      hamburger,
    };
  },
  methods: {
    showMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    leistungen() {
      this.$emit("leistungen");
    },
    aboutMe() {
      this.$emit("aboutMe");
    },
    contact() {
      this.$emit("contact");
    },
  },
};
</script>

<style lang="less" scoped>
@color-black-primary: rgb(7, 6, 6);
@header-footer-height-mobile: 9em;
@mobile-font-size: 14px;
.nav-menu {
  background-color: @color-black-primary;
  position: fixed;
  width: 100%;
  z-index: 9999;
  height: 4rem;

  .arrow {
    clip-path: polygon(0 0, 100% 0, 100% 0, 50% 100%, 0 0);
    height: 49px;
    width: 100%;
    position: absolute;
    top: 63px;
    background-color: @color-black-primary;

    @media screen and (max-width: 700px) {
      display: none;
    }
  }

  .arrow2 {
    clip-path: polygon(0 0, 100% 0, 100% 10%, 50% 80%, 0 10%);
    height: 75px;
    width: 100%;
    position: absolute;
    top: 63px;
    z-index: -9998;
    background-color: rgb(146, 86, 86);

    @media screen and (max-width: 700px) {
      display: none;
    }
  }
}

.nav-content {
  display: flex;
  justify-content: center;
  padding: 10px 30px;
  align-items: center;
}
.nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    padding: 0 10px;
    cursor: pointer;
  }
  li:hover {
    text-decoration: underline;
  }
}
i {
  display: none;
}
// Mobile version - hidden hamburger menu
@media screen and (max-width: 500px) {
  .logo {
    display: none;
  }
  .nav-menu {
    padding-top: 10px;
    position: absolute;
    width: 100%;
  }
  .open-menu {
    opacity: 1;
    height: 150px;
  }
  .closed-menu {
    opacity: 0;
    height: 0;
    padding: 0;
  }
  .nav-content {
    flex-direction: column;
    z-index: 100;
    transition: all 0.2s ease-out;
    background-color: rgba(10, 9, 9, 0.863);
    border-radius: 5px;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .nav-items {
    flex-direction: column;
    gap: 1.5em;
  }
  i {
    display: block;
    text-align: right;
    padding: 0 10px 10px 0;
    position: fixed;
    top: 8px;
    right: 5px;
    z-index: 9999;
    cursor: pointer;
  }
}
</style>
