<template>
  <div class="wrapper">
    <h1>Kontakt</h1>
    <p>Kommen wir in Kontakt – unverbindlich auf ein Telefonat!</p>
    <p>Telefonnummer: 07951 295633</p>
    <p>...oder gerne auch per Mail:</p>
  </div>

  <div class="container" id="kontakt">
    <form @submit.prevent="makeRequest">
      <div class="form-group">
        <input
          type="text"
          name="name"
          class="form-control name"
          placeholder="Dein Name"
          required
          @change="saveName"
        />

        <input
          type="email"
          name="email"
          class="form-control email"
          placeholder="Deine Email Addresse"
          required
          @change="saveEmail"
        />

        <textarea
          placeholder="Deine Nachricht"
          class="form-control message"
          name="message"
          rows="10"
          required
          @change="saveMessage"
        ></textarea>

        <button type="submit" class="btn btn-lg btn-dark btn-block">
          Senden
        </button>
        <div :class="classSubmit">
          Danke für deine Nachricht! Ich werde schnellstmöglich antworten. Bis
          bald.
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      message: "",
      email: "",
      classSubmit: "noSubmit",
    };
  },
  methods: {
    saveName(event) {
      this.name = event.target.value;
    },

    saveMessage(event) {
      this.message = event.target.value;
    },

    saveEmail(event) {
      this.email = event.target.value;
    },

    async makeRequest() {
      try {
        const response = await fetch(
          "https://formsubmit.co/ajax/tonbildhauer.info@gmail.com",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              name: this.name,
              message: this.message,
              email: this.email,
            }),
          }
        );

        const data = await response.json();

        if (data.success === "true") {
          this.classSubmit = "submitted";
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@color-black-primary: rgb(7, 6, 6);
@border-color: rgba(255, 255, 255, 0.171);

.wrapper {
  margin-top: 5rem;
  margin-bottom: 3rem;
  max-width: 1300px;
  margin-inline: auto;

  padding: 1rem;
}
.noSubmit {
  visibility: hidden;
}

.submitted {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(124, 124, 124, 0.253));
  box-shadow: 0px 0px 0px 1px @border-color;
  border-radius: 5px;
  text-align: center;
  margin-block: 1rem;
  padding: 0.5rem;
  opacity: 0;
  transition: opacity 15s ease;
}

.submitted.show {
  visibility: visible;
  opacity: 1;
}
p {
  text-align: center;
}
.container {
  margin-block: 2rem;
  width: 50%;
  margin-inline: auto;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.171);
  padding: 1rem;

  border-radius: 2px;
  @media (max-width: 1250px) {
    width: 70%;
  }

  form {
    margin-top: 1rem;

    .form-group {
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;

      .form-control {
        border-radius: 5px;
        background-color: @color-black-primary;
        color: white;
        transition: 0.5s border;
        margin-block: 0.3rem;
        width: 100%;
        border: 1px solid @border-color;
      }
      .form-control:hover {
        box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.349);
      }
    }
    .name {
      height: 30px;
    }
    .email {
      height: 30px;
    }

    .message {
      border-radius: 10px;
    }

    button {
      transition: 0.5s all;
      border-radius: 5px;
      border: 2px solid white;
      height: 30px;
      margin-block: 0.3rem;
      font-family: georgia, serif;
      font-size: 1.1rem;
    }
    button:hover {
      box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.349);
    }
  }
}
</style>
