<template>
  <footer>
    <div>@ 2023 Tonbildhauer.de</div>
    <div>E-Mail: tonbildhauer.info@gmail.com</div>
    <div>Telefonnummer: 07951 295633</div>
  </footer>
</template>

<style lang="less" scoped>
footer {
  @color-black-primary: rgb(7, 6, 6);
  @header-footer-height-mobile: 5em;
  @mobile-font-size: 14px;

  margin-top: 5rem;
  padding-inline: auto;
  padding-block: 1rem;
  width: 100%;
  height: @header-footer-height-mobile;
  background-color: @color-black-primary;
  text-align: left;
  position: relative;
  display: flex;
  justify-content: space-around;
  z-index: 9999;
  border-top: 10px solid rgb(146, 86, 86);

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 0.5em;
    font-size: @mobile-font-size;
    justify-content: center;
    height: 7rem;
    padding-left: 1em;
  }
}
</style>
