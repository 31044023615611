<template>
  <div class="wrapper">
    <div class="imgwrapper">
      <p class="content">
        <strong>"</strong>Ich glaube, dass jede Skulptur eine eigene Geschichte
        erzählt und es meine Aufgabe ist, sie durch meine Kunst zum Ausdruck zu
        bringen<strong>"</strong>
      </p>
      <img class="img" alt="Phoenix" src="../assets/imgs/female.jpg" />
    </div>
  </div>
</template>

<style scoped lang="less">
.imgwrapper {
  height: 500px;
  overflow: hidden;
  position: relative;

  .img {
    opacity: 0.2;
    z-index: -1;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .content {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    font-size: 2rem;
    width: 100%;
    opacity: 0.9;
    padding-inline: 4em;
    text-align: center;

    @media (max-width: 750px) {
      font-size: 1.5rem;
      padding-inline: 2em;
    }
    @media (max-width: 450px) {
      font-size: 1.2rem;
    }
  }
}
</style>
